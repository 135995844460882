<template>
	<div style="margin:20px 0 0 0;" class="sameWidth">
		<div style="margin:0;">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/companyActive' }">公司业务</el-breadcrumb-item>
				<el-breadcrumb-item>招标代理</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin:0;text-align: center;">
			<h1 class="font2">招标代理</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="width: 50%;display: inline-block;vertical-align: middle;">
				<img src="../../../assets/images/companyActive/公司业务—代理.jpg" style="width: 100%;"/>
			</div><div style="width: 50%;display: inline-block;vertical-align:top;">
				<p style="text-indent: 2em;padding-left: 20px;font-size: 16px;color: #595959;line-height: 30px;text-align: left;" >
					招投研究院具有独立编制有关招标文件、有效组织评标活动的专业团队和技术设施，具有多年从事招标代理业务的经验，不但为招标人提供合法合规、专业高效的服务，而且能为招标人解决很多招标采购中的棘手问题。
				</p>
				<p style="margin-top: 30px;padding-left: 20px;font-size: 16px;color: #595959;line-height: 30px;text-align: left;">
					招投研究院为客户创造的价值主要包括：<br/>
					1、协助客户合法合规的完成招标采购工作流程；<br/>
					2、协助客户选择最优的中标方；<br/>
					3、在招标采购阶段协助客户选择最优化的投资预算方案；<br/>
					4、提升客户的规范经营水平，规避无谓的风险。
				</p>
			</div>
		</div>
		<div style="margin: 100px  0;text-align: center;">
			<h1  class="font2">服务对象</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;text-align: center;">
				<div style="flex: 1;padding: 20px;">
					<i class="iconfont icon-qishiyedanwei" style="font-size:80px;color:#409EFF ;"></i>
					<h3 style="color: #595959;">政府部门</h3>
				</div>
				<div style="flex: 1;padding: 20px;">
					<i class="iconfont icon-touzijigou" style="font-size:80px;color:#409EFF ;"></i>
					<h3 style="color: #595959;">企事业单位</h3>
				</div>
				<div style="flex: 1;padding: 20px;">
					<i class="iconfont icon-yiliaohangyedeICON-" style="font-size:80px;color:#409EFF ;"></i>
					<h3 style="color: #595959;">医院、学校</h3>
				</div>
			</div>
		</div>
		<div style="text-align: center;margin: 100px  0;">
			<h1  class="font2">服务流程</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div class="step" style="display:flex;">
				<div style="flex: 1;box-sizing: border-box;" v-for="(item,index) in liucheng.slice(0,5)" :key="index">
					<div v-if="index!=4" style="padding-bottom: 50px;">
						<div class="stepNum">
							<p v-if="index<9">0{{index+1}}</p>
							<p v-else>{{index+1}}</p>
						</div>
						<p class="stepline"></p>
						<p class="stepTitle" style="font-size: 18px;font-weight: 550;">{{item.title}}</p>
						<p class="stepDes">{{item.des}}</p>
					</div>
					<div v-else style="height: 100%;padding-bottom: 50px;">
						<div style="display: inline-block;width: 92%;vertical-align: top;">
							<div class="stepNum">
								<p v-if="index<9">0{{index+1}}</p>
								<p v-else>{{index+1}}</p>
							</div>
							<p class="stepline"></p>
							
							<p class="stepTitle" style="font-size: 18px;font-weight: 550;">{{item.title}}</p>
							<p class="stepDes">{{item.des}}</p>
						</div>
						<div style="display: inline-block;height: 100%;width:4px;background-color:#409EFF;vertical-align: top;margin-top:56px;margin-left:-4px;"></div>
						
					</div>
				</div>
			</div>
			<div class="step" style="display: flex;">
				<div style="flex: 1;" v-for="(item,index) in liucheng.slice(5,10).reverse()" :key="index">
					<div v-if="index!=4" :style="{}">
						<div class="stepNum">
							<p v-if="index==0">{{10-index}}</p>
							<p v-else>0{{10-index}}</p>
						</div>
						<p class="stepline"></p>
						<p class="stepTitle" style="font-size: 18px;font-weight: 550;">{{item.title}}</p>
						<p class="stepDes">{{item.des}}</p>
					</div>
					<div v-else style="height: 100%;">
						<div style="display: inline-block;width: 92%;vertical-align: top;">
							<div class="stepNum">
								<p v-if="index==0">0{{10-index}}</p>
								<p v-else>0{{10-index}}</p>
							</div>
							<p class="stepline"></p>
							
							<p class="stepTitle" style="font-size: 18px;font-weight: 550;">{{item.title}}</p>
							<p class="stepDes">{{item.des}}</p>
						</div>
						<div style="display: inline-block;height: 100%;width:4px;vertical-align: top;margin-top:56px;margin-left:-4px"></div>
					</div>
				</div>
			</div>
		</div>
		
		<div style="margin: 100px 0;text-align: center;" class="sameWidth">
			<h1  class="font2">服务优势</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;" class="banner4">
				<div style="flex:1;padding: 0;" v-for="item in youshi" :key="item.id">
					<div style="margin:10px;box-shadow: 10px 10px 5px #888888;height: 100%;">
						<img :src="item.src" class="image" />
						<div style="padding:0 20px">
							<h3>{{ item.title }}</h3>
							<p style="color: #909399;text-align: left;line-height: 25px;font-size: 14px;">{{ item.desc }}</p>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<div style="margin:100px 0;text-align: center;display: none;" class="sameWidth">
			<h1  class="font2">经典案例</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;" class="banner4">
				<div style="flex:1;padding: 0;" v-for="item in youshi" :key="item.id">
					<div style="margin:20px;box-shadow: 10px 10px 5px #888888;height: 100%;">
						<img :src="item.src" class="image" />
						<div style="padding:0 20px">
							<h3>{{ item.title }}</h3>
							<p style="color: #909399;text-align: left;line-height: 25px;">{{ item.desc }}</p>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name:'tenderAgent',
	metaInfo: {
		title: '招标代理',
		meta: [{
				name: 'keywords',
				content: '招标代理,招标文件,开标,评标,定标,中标'
			},
			{
				name: 'description',
				content: '招投研究院具有独立编制有关招标文件、有效组织评标活动的专业团队和技术设施，具有多年从事招标代理业务的经验，不但为招标人提供合法合规、专业高效的服务，而且能为招标人解决很多招标采购中的棘手问题。'
			}
		]
	},
	data() {
		return {
			screenWidth:1300,
			youshi: [
				{
				id: 1,
				src: require("../../../assets/images/tenderAgent/经验丰富.jpg"),
				title: "业务经验丰富",
				desc:'　　核心团队具有十余年招标代理、政府采购代理、招标规范化审计经验，累计完成代理额超过100亿元。',
				},
				{
				id: 2,
				src: require("../../../assets/images/tenderAgent/专业团队.jpg"),
				title: "专业团队",
				desc:'　　招投研究院80%以上为本科以上学历或中高级以上职称；聘请了原广东省财政厅政府采购监管处退休专家、,华南理工大学工商管理学院教授、广东正大方略律师事务所高级合伙人等一批专家团队，强大的专家团队确保项目顺利履行。',
				},
				{
				id: 3,
				src: require("../../../assets/images/tenderAgent/良好口碑.jpg"),
				title: "良好口碑",
				desc:'　　近年来服务广东、湖南、湖北、山东、广西、云南等地区的 300 多家企事业单位，取得了良好的社会声誉。目前主要行业包括：政府部门、企事业单位、医院、学校。',
				}
			],
			liucheng:[
				{title:'采购计划',des:'制定采购计划'},
				{title:'项目启动',des:'初步洽谈,召开标前会议'},
				/*
				{title:'确定招标方式',des:'公开招标或邀请招标'},
				{title:'资格审查方式',des:'资格预审或资格后审'},
				*/
				{title:'编制招标文件',des:'进行招标文件编制'},
				{title:'发布招标公告',des:'在国家指定网站、媒体发布公告信息'},
				{title:'开标',des:'安排会场，组织招标人、投标人进行开标'},
				{title:'评标',des:'组织评标专家进行评审'},
				{title:'定标',des:'专家评审后，确定中标候选人'},
				{title:'发布中标候选人公示',des:'在国家指定网站、媒体发布中标候选人信息'},
				{title:'发布中标结果公示',des:'在国家指定网站、媒体发布中标结果信息'},
				{title:'备案',des:'完成项目备案'}
			],
		}
	},
	methods: {
		goTo(val){
			this.$router.push({name:'tenderInfoContent',params:val.row})
		}
	},
	mounted:function(){
		this.bus.$emit('headerImg',this.headerImg);
		this.screenWidth=document.documentElement.clientWidth;
		window.addEventListener('resize',()=>{
			this.screenWidth=document.documentElement.clientWidth;
		})
	}
}
</script>

<style scoped>
	.image{width: 100%;}
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
	}
	.borderBottom{
		margin-top: -40px;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 50px;
	}
	.step .stepNum{padding:10px;margin:0;float:left;background-color:#fff;}
	.step .stepNum p{
		background-color:#409EFF;
		border-radius:40px;
		width:80px;
		height:80px;
		line-height:80px;
		margin: 0;
		color:#fff;
		font-size: 30px;
	}
	.step .stepline{padding:0;margin:0;border-bottom:#409EFF 4px solid;z-index: -1;position: relative;top:52px;}
	.step .stepTitle{
		text-align:center;
		clear: both;
		margin-left: -50%;
		max-width: 100%;
		position: relative;
		left:50px
	}
	.step .stepDes{
		text-align:center;
		color: #909399;
		margin-left: -50%;
		max-width: 100%;
		padding: 0 20px;
		position: relative;
		left:50px;
		box-sizing: border-box;
		line-height: 30px;
	}
</style>
